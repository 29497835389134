module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KNMBQ4Z","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kubric Website","short_name":"Kubric","start_url":"/","background_color":"#5c40cf","theme_color":"#5c40cf","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3b025b8e668e4c23da248772cf99f826"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
